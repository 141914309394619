import React from "react"
import "../css/footer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome.js';

export default function Footer() {
  return (
    <div className="footer p-4">
      <div className="social-media-links flex justify-center space-x-4 p-2">
        <a className='icons' href='https://www.facebook.com/ZoeTV/' target='_blank'>
          <FontAwesomeIcon icon={['fab', 'facebook-f']} mask={['fas', 'circle']} transform="shrink-6" size="2x"/>
        </a>
      </div>
      <div className="justify-center">
        <p className="text-center uppercase underline underline-offset-4 pb-4">© 2022 ZOE Broadcasting Network INC. All Rights Reserved.</p>
      </div>
    </div>
  )
}
