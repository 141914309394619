import React from "react"

import Header from "./Header"
import Footer from "./Footer"
import "../css/index.css"

import { Helmet } from "react-helmet"

export default function Layout({ children }) {
  return (
    <div className="main min-h-screen flex flex-col justify-between">
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/jquery-modal/0.9.1/jquery.modal.min.css" />
      </Helmet>
      <div className="container mx-auto">
        <Header />
      </div>
      <div className="container mx-auto">
        <div className="p-6 content">
          { children }
        </div>
      </div>
      <Footer />
    </div>
  )
}
