import React from "react"
import Layout from '../components/Layout'
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome.js';
import {Link, graphql} from "gatsby"


function Zoe({ node }) {
  const {slug, logoImg} = node.frontmatter
  return (
    <div className="p-4 basis-3/4 md:basis-full lg:basis-1/3 items-center text-center">
      <div className="m-auto w-full md:w-1/2 lg:w-full">
        <a href={"#" + slug} rel="modal:open">
          <GatsbyImage image={logoImg.childImageSharp.gatsbyImageData} alt="ZOE Logo" />
        </a>
        <SocialMediaAndModal node={node} />
      </div>
    </div>
  );
}

function SocialMediaLinks({ node }) {
  const props = node.frontmatter
  const l = ['facebook', 'twitter', 'youtube', 'instagram', 'tiktok', 'linkedin']
  var links = []

  l.forEach((key) => {
    if (props[key]) {
      const iconName = key === 'facebook' ? 'facebook-f' : key;
      links.push({
        iconName: iconName, 
        name: key, 
        val: props[key]
      })
    }
  })
  
  return (
    <>
      {links.map(link => ( 
        <a key={link.name} className="icons" target="_blank" href={link.val}>
          <FontAwesomeIcon icon={['fab', link.iconName]} mask={['fas', 'circle']} transform="shrink-6" size="2x"/>
        </a>
      ))}
    </>
  )
}

function LightTvLink({ slug }) {
  if (slug === "lighttv") {
    return(
      <div className="text-center pb-4 pt-1 text-2xl">
        <Link target="_blank" style={{color: "#086788"}} to="https://lighttv.ph/">{"https://lighttv.ph/"}</Link>
      </div>
    )
  }

  return <></>
}

function SocialMediaAndModal({ node }) {
  const {slug, logoImg, logoImg_alt} = node.frontmatter
  const { images } = logoImg.childImageSharp.gatsbyImageData

  return (
    <div id={ slug } className="modal" style={{display: "hidden"}}>
      <div className="text-center">
        <div className="w-full m-auto md:w-2/3">
          <picture>
            <source
              type={images.sources[0].type}
              sizes={images.sources[0].sizes}
              srcset={images.sources[0].srcSet}
            />
            <img sizes={images.fallback.sizes} alt={logoImg_alt}
              src={images.fallback.src} 
              srcset={images.fallback.srcSet}
            />
          </picture>
        </div>
        <div className="pt-8 pb-4 text-xl md:text-2xl text-justify" dangerouslySetInnerHTML={{ __html: node.html }} />
        <hr className="pb-4"/>
        <div className="social-media-links flex justify-center space-x-4 p-2">
          <SocialMediaLinks node={node} />
        </div>
        <LightTvLink slug={slug} />
      </div>
    </div>
  )
}


function Logo({ node }) {
  const { slug, logoImg, logoImg_alt } = node.frontmatter

  // remove light-palawan's modal for now. 
  const rel = slug !== 'light-palawan' ? "modal:open" : "none"
  return(
    <div className="p-5 logo basis-1/2 md:basis-1/4 lg:basis-1/4 text-center">
      <a href={"#" + slug} rel={rel}>
        <GatsbyImage 
          image={logoImg.childImageSharp.gatsbyImageData}
          alt={logoImg_alt} 
        />
      </a>
      <SocialMediaAndModal node={node} />
    </div>
  )
}



function Logos({ data }) {
  const { nodes } = data.allMarkdownRemark

  return (
    <>
      <Zoe node={nodes[0]} />
      <div className="basis-full md:basis-full lg:basis-2/3">
        <div className="flex flex-wrap">
          {nodes.slice(1).map(node => (
            <Logo key={node.frontmatter.slug} node={node} />
          ))}
        </div>
      </div>
    </>
  )
}

export default function Home({ data }) {
  return (
    <Layout>
      <div className="md:p-8 flex flex-wrap justify-center lg:items-center">
        <Logos data={data} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allMarkdownRemark(sort: {fields: frontmatter___weight, order: ASC}) {
      nodes {
        frontmatter {
          title
          slug
          logoImg_alt
          facebook
          instagram
          twitter
          tiktok
          linkedin
          youtube
          logoImg {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        html
      }
    }
  }
`
