import React from "react"
import { Link } from "gatsby"
import "../css/header.css"

export default function Header() {
  return (
    <header className="header md:pt-10">
      <nav className="navbar">
        <div className="hamburger">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <div className="nav-menu">
          <li className="nav-item">
              <Link to="/" className="hover:underline underline-offset-4 uppercase nav-link">Home</Link>
          </li>
          <li className="nav-item">
              <a href="#zoetv" rel="modal:open" className="hover:underline underline-offset-4 uppercase nav-link">About Us</a>
          </li>
          <li className="nav-item">
              <Link to="#" className="hover:underline underline-offset-4 uppercase nav-link">Careers</Link>
          </li>
          <li className="nav-item">
              <Link to="#" className="hover:underline underline-offset-4 uppercase nav-link">Advertise With Us</Link>
          </li>
        </div>
      </nav>
    </header>
  )
}
